import "./document.css";
import axios from "axios";
import moment from "moment";
import Layout from "../../../Layout";
import localization from "moment/locale/en-in";
import PaginationNew from "../../PaginationNew";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdOutlineGroups } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { Modal, Table, Form } from "react-bootstrap";
import { getAllSchool } from "../../../controller/api";
import { CircularProgress, Avatar } from "@mui/material";
import { Button, Dialog, DialogContent } from "@mui/material";
import { addSuperlatives, addGroups } from "../../../controller/api";
import {
  EditIcon,
  UserIcon,
  UsersIcon,
  AwardIcon,
  SearchIcon,
  DeleteIcon,
  SortingIcon,
  AlertCircle,
  DeleteDialogIcon,
} from "../../../Icons";

const Schools = () => {
  const [name, setName] = useState("");
  const [rev, setRev] = useState(false);
  const [show, setShow] = useState(false);
  const [address, setAddress] = useState("");
  const [newName, setNewName] = useState("");
  const [loading, setLoading] = useState(true);
  const [schoolId, setSchoolId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [onSubmit, setOnSubmit] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [allSchools, setAllSchools] = useState([]);
  const [superShow, setSuperShow] = useState(false);
  const [groupShow, setGroupShow] = useState(false);
  const [current_page, setCurrent_page] = useState(1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [schoolIconId, setSchoolIconId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const perPage = 10;

  moment.updateLocale("en-in", localization);

  useEffect(() => {
    document.title = "Skoolfame | Schools";

    AllSchool();
  }, [searchData, rev]);

  const AllSchool = async (pages) => {
    try {
      const SchoolData = await getAllSchool(perPage, pages, searchData, rev);
      const {
        data,
        status,
        message,
        pagination_value,
        current_page: page,
      } = SchoolData;
      if (status === 1) {
        setAllSchools(data);
        setPaginationVal(pagination_value);
        setCurrent_page(page);
        setLoading(false);
      } else {
        toast.error(message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error, "school-page-error");
    }
  };

  const rever = () => setRev(!rev);
  const handleShow = () => setShow(true);
  const handleSuperShow = () => setSuperShow(true);
  const handleGroupsShow = () => setGroupShow(true);

  const handleClose = () => {
    setName("");
    setAddress("");
    setNewName("");
    setShow(false);
    setImageUrl("");
    setSuperShow(false);
    setGroupShow(false);
    setSchoolIconId(null);
    setSelectedImage(null);
  };

  const deletSchool = async () => {
    if (schoolId !== "") {
      try {
        const res = await axios.delete(`/delete-all-school?id=${schoolId}`);
        if (res.data.status === 1) {
          toast.success(res.data.message);
          AllSchool(current_page);
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.log(error, "school-page-error");
      }
    }
  };

  // SUPERLATIVES HELPER METHODS...
  const saveSuperlatives = async () => {
    if (newName.trim().length === 0) {
      toast.error("Please enter name");
      return;
    }

    setOnSubmit(true);

    try {
      const res = await addSuperlatives(newName, schoolIconId);
      if (res.status === 1) {
        setSuperShow(false);
        setNewName("");
        setLoading(true);
        setOnSubmit(false);

        AllSchool(1);
        toast.success(res.message);
      } else {
        setOnSubmit(false);
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }

    setSchoolIconId(null);
  };

  // GROUPS HELPER METHODS...
  const handleFileInputChange = (e) => {
    const uploadedFile = e.target.files[0];
    setSelectedImage(uploadedFile);

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };

      reader.readAsDataURL(uploadedFile);
    }
  };

  const handleDeletFileClick = () => {
    setSelectedImage(null);
    setImageUrl("");
  };

  const saveGroups = async () => {
    if (newName.trim().length === 0) {
      toast.error("Please enter group name");
      return;
    } else if (selectedImage === null) {
      toast.error("Please enter group image");
      return;
    }

    setOnSubmit(true);

    const formData = new FormData();
    formData.append("name", newName);
    formData.append("file", selectedImage);

    try {
      const res = await addGroups(formData, schoolIconId);

      if (res?.status === 1) {
        setNewName("");
        setImageUrl("");
        setSelectedImage(null);

        setGroupShow(false);
        setLoading(true);
        setOnSubmit(false);

        AllSchool(1);
        toast.success(res?.message);
      } else {
        setOnSubmit(false);
        toast.error(res?.message);
      }
    } catch (error) {
      console.log(error);
    }

    setSchoolIconId(null);
  };

  const addSchool = async (e) => {
    e.preventDefault();
    if (name.trim().length === 0 || address.trim().length === 0) {
      toast.error("Please enter empty fields");
      setAddress("");
      setName("");
      return;
    }
    try {
      const SchoolData = await axios.post("/add-school", {
        name: name.trim(),
        address: address.trim(),
      });
      if (SchoolData.data.status === 1) {
        setAddress("");
        setName("");
        setShow(false);
        toast.success("School added successfully");
        AllSchool(1);
      }
    } catch (error) {
      console.log(error, "school-page-error");
    }
  };

  return (
    <Layout>
      <div className="home-main">
        <div className="school-main-heading d-flex justify-content-between align-items-center">
          Schools
          <Link
            to="/add_school"
            onClick={handleShow}
            style={{ textDecoration: "none" }}
          >
            <button className="custom-btn">Add School</button>
          </Link>
        </div>

        <div className="school-data-heading d-flex align-items-center justify-content-between">
          <h1 className="heading-data">All Schools</h1>
          <div className=" d-flex align-items-center">
            <div className="form-group has-search">
              <span className="form-control-feedback">
                <SearchIcon />
              </span>
              <input
                className="form-control"
                type="text"
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
                placeholder="Search by keyword"
              />
            </div>
          </div>
        </div>

        {loading ? (
          <h1 className="lod">
            <CircularProgress />
          </h1>
        ) : allSchools.length === 0 ? (
          <h2 className="lod pt">No data available of schools</h2>
        ) : (
          <>
            <div className="custom-data-table">
              <Table responsive className="mb-0 px-4 pb-2">
                <thead>
                  <tr>
                    <th className="table-heading" width="8%">
                      Image
                    </th>
                    <th className="table-heading" width="19%">
                      School Name
                    </th>
                    <th className="table-heading" width="13%">
                      States
                    </th>
                    <th className="table-heading" width="13%">
                      Education Level
                    </th>
                    <th className="table-heading" width="6%">
                      Groups
                    </th>
                    <th className="table-heading" width="8%">
                      Superlatives
                    </th>
                    <th className="table-heading" width="8%">
                      Users
                    </th>
                    <th className="table-heading" width="20%">
                      Created At
                      <span
                        style={{ marginLeft: "2px", cursor: "pointer" }}
                        onClick={rever}
                      >
                        <SortingIcon />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allSchools?.map((school, i) => {
                    const {
                      _id,
                      image,
                      name,
                      state,
                      groups,
                      createdAt,
                      schoolType,
                      school_user,
                      superlatives,
                    } = school;

                    return (
                      <tr
                        key={_id}
                        className={i % 2 === 0 ? "even-row" : "odd-row"}
                      >
                        <td className="table-data" width="6%">
                          <Avatar
                            alt={name}
                            src={image}
                            sx={{ width: 32, height: 32 }}
                          />
                        </td>
                        <td className="table-data" width="19%">
                          <div className="delete-group ">{name}</div>
                        </td>
                        <td className="table-data" width="13%">
                          {state}
                        </td>
                        <td className="table-data" width="13%">
                          {schoolType ? schoolType : ""}
                        </td>
                        <td className="table-data" width="6%">
                          {groups ? groups?.length : 0}
                        </td>
                        <td className="table-data" width="8%">
                          {superlatives ? superlatives?.length : 0}
                        </td>
                        <td className="table-data" width="8%">
                          {school_user ? school_user : "0"}
                        </td>
                        <td className="table-data" width="13%">
                          {moment(createdAt).format("L")}
                        </td>
                        <td className="table-data" width="15%">
                          <div className="delete-group d-flex align-items-center justify-content-end gap-3">
                            <span style={{ cursor: "pointer" }}>
                              <Link
                                to={`/Groups/${_id}`}
                                className="p-0 text-decoration-none"
                                style={{ color: "#000" }}
                              >
                                <MdOutlineGroups
                                  style={{
                                    color: "#667085",
                                    height: "28px",
                                    width: "28px",
                                  }}
                                />
                              </Link>
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleSuperShow();
                                setSchoolIconId(_id);
                              }}
                            >
                              <AwardIcon />
                            </span>
                            <span style={{ cursor: "pointer" }}>
                              <Link
                                to={`/Superlatives/${_id}`}
                                className="p-0 text-decoration-none"
                                style={{ color: "#000" }}
                              >
                                <AlertCircle />
                              </Link>
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleGroupsShow();
                                setSchoolIconId(_id);
                              }}
                            >
                              {/* <Link
                            to={`/Groups/${_id}`}
                            className="p-0 text-decoration-none"
                            style={{ color: "#000" }}
                          > */}
                              <UsersIcon />
                              {/* </Link> */}
                            </span>
                            <span style={{ cursor: "pointer" }}>
                              <Link
                                to={`/edit_school/${_id}`}
                                className="p-0 text-decoration-none"
                                style={{ color: "#000" }}
                              >
                                <EditIcon />
                              </Link>
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setOpenDeleteDialog(true);
                                setSchoolId(_id);
                              }}
                            >
                              <DeleteIcon />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-end mt-4 w-100">
              <PaginationNew
                AllUser={AllSchool}
                pagination={paginationVal}
                current_page={current_page}
              />
            </div>
          </>
        )}
      </div>

      <Modal
        show={show}
        centered
        onHide={handleClose}
        className="modal_superlative"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add School</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGridEmail" className="mt-4">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
            <Button className="submit-btn" onClick={addSchool}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Dialog
        open={superShow}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent className="custom-delete-dialog">
          <p className="heading mx-auto mb-3">Add Superlatives</p>

          <Form>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newName}
                  required={true}
                  placeholder="Enter Name"
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Form.Group>
            </div>

            {newName && (
              <>
                <button
                  disabled={onSubmit}
                  className="custom-btn w-100"
                  onClick={saveSuperlatives}
                >
                  {onSubmit ? "Submitting..." : "Submit"}
                </button>

                {onSubmit && (
                  <p className="group-image-heading mt-3 text-danger">
                    It may require some time. So, please be patient
                  </p>
                )}
              </>
            )}
          </Form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={groupShow}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent className="custom-delete-dialog">
          <p className="heading mx-auto mb-3">Add Groups</p>

          <Form>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">Group Name</Form.Label>
                <Form.Control
                  type="text"
                  required={true}
                  value={newName}
                  placeholder="Enter Name"
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Form.Group>

              {selectedImage ? (
                <div className="container mt-3 mb-3">
                  <img src={imageUrl} alt="Avatar" className="image" />
                  <div className="overlay">
                    <button className="icon" onClick={handleDeletFileClick}>
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-3">
                  <input
                    type="file"
                    id="file-input"
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    accept="image/jpeg, image/png, image/svg+xml"
                  />

                  <label htmlFor="file-input">
                    <UserIcon />
                  </label>
                </div>
              )}

              <p className="group-image-heading mt-2">
                {selectedImage !== null ? selectedImage.name : "Add Image"}
              </p>

              {newName !== "" && selectedImage !== null && (
                <>
                  <button
                    disabled={onSubmit}
                    onClick={saveGroups}
                    className="custom-btn w-100"
                  >
                    {onSubmit ? "Submitting..." : "Submit"}
                  </button>

                  {onSubmit && (
                    <p className="group-image-heading mt-3 text-danger">
                      It may require some time. So, please be patient
                    </p>
                  )}
                </>
              )}
            </div>
          </Form>
        </DialogContent>
      </Dialog>

      <Dialog open={openDeleteDialog} aria-labelledby="responsive-dialog-title">
        <DialogContent className="custom-delete-dialog">
          <div className="text-center">
            <DeleteDialogIcon />
          </div>
          <p className="heading mt-3">Delete School</p>
          <p className="data my-3">
            Are you sure you want to delete this School? This action cannot be
            undone.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="dialog-btn"
              style={{ color: "black" }}
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="dialog-btn"
              style={{ color: "white", background: "red", marginLeft: "20px" }}
              onClick={() => {
                setOpenDeleteDialog(false);
                deletSchool();
              }}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default Schools;
