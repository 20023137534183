import moment from "moment";
import Layout from "../../../Layout";
import localization from "moment/locale/en-in";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { Table, Button, Form } from "react-bootstrap";
import { CircularProgress, Avatar } from "@mui/material";
import {
  getCommonGroups,
  postCommonGroups,
  patchCommonGroups,
  removeCommonGroups,
} from "../../../controller/api";
import {
  EditIcon,
  UserIcon,
  SearchIcon,
  DeleteIcon,
  DeleteDialogIcon,
} from "../../../Icons";

const CommonGroups = () => {
  const [schools, setSchools] = useState([]);
  const [newName, setNewName] = useState("");
  const [oldName, setOldName] = useState("");
  const [loading, setLoading] = useState(true);
  const [schoolId, setSchoolId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [onSubmit, setOnSubmit] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [groupShow, setGroupShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  moment.updateLocale("en-in", localization);

  useEffect(() => {
    document.title = "Skoolfame | Common Groups";
  }, []);

  useEffect(() => {
    fetchCommonGroups();
  }, [searchData]);

  const handleGroupsShow = () => setGroupShow(true);

  const handleCreateClose = () => {
    setNewName("");
    setOldName("");
    setImageUrl("");
    setSelectedImage(null);

    setGroupShow(false);
  };

  const handleEditClose = () => {
    setNewName("");
    setOldName("");
    setImageUrl("");
    setSelectedImage(null);
    setOpenEditDialog(false);
  };

  const handleDeletFileClick = () => {
    setSelectedImage(null);
    setImageUrl("");
  };

  const handleFileInputChange = (e) => {
    const uploadedFile = e.target.files[0];
    setSelectedImage(uploadedFile);

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };

      reader.readAsDataURL(uploadedFile);
    }
  };

  const onEditIconClick = (name, image) => {
    setNewName(name);
    setOldName(name);
    setImageUrl(image);
    setSelectedImage(image);

    setOpenEditDialog(true);
  };

  const fetchCommonGroups = async () => {
    try {
      const commonGroups = await getCommonGroups(searchData);
      const { status, message, data } = commonGroups;

      if (status === 1) {
        setSchools(data);
        setLoading(false);
      } else {
        toast.error(message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCommonGroups = async () => {
    try {
      const res = await removeCommonGroups(schoolId);

      if (res.status === 1) {
        toast.success(res.message);
        setSchools((previousSchools) =>
          previousSchools.filter((school) => school._id != schoolId)
        );
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error, "school-page-error");
    }
  };

  const updateCommonGroups = async () => {
    try {
      if (newName.trim().length === 0) {
        toast.error("Please enter group name");
        return;
      } else if (selectedImage === null) {
        toast.error("Please enter group image");
        return;
      }

      setOnSubmit(true);

      const formData = new FormData();
      formData.append("oldName", oldName);
      formData.append("newName", newName);
      formData.append("file", selectedImage);

      const res = await patchCommonGroups(formData);

      if (res.status === 1) {
        setOnSubmit(false);
        toast.success(res.message);

        fetchCommonGroups();
      } else {
        setOnSubmit(false);
        toast.error(res.message);
      }

      setOldName("");
      setNewName("");
      setOpenEditDialog(false);
    } catch (error) {
      console.log(error, "school-page-error");
    }
  };

  const saveGroups = async () => {
    if (newName.trim().length === 0) {
      toast.error("Please enter group name");
      return;
    } else if (selectedImage === null) {
      toast.error("Please enter group image");
      return;
    }

    setOnSubmit(true);

    const formData = new FormData();
    formData.append("name", newName);
    formData.append("file", selectedImage);

    try {
      const res = await postCommonGroups(formData);

      if (res?.status === 1) {
        setNewName("");
        setImageUrl("");
        setSelectedImage(null);

        setGroupShow(false);
        setLoading(true);
        setOnSubmit(false);

        fetchCommonGroups();
        toast.success(res?.message);
      } else {
        setOnSubmit(false);
        toast.error(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="home-main">
        <div className="user-data w-100">
          <div className="school-main-heading d-flex justify-content-between align-items-center">
            Common Groups
            <Link onClick={handleGroupsShow} style={{ textDecoration: "none" }}>
              <button className="custom-btn">Add Group</button>
            </Link>
          </div>

          <div className="school-data-heading w-100">
            <div className="d-flex flex-row-reverse">
              <div className="form-group has-search ms-2">
                <span className="form-control-feedback">
                  <SearchIcon />
                </span>
                <input
                  className="form-control"
                  type="text"
                  value={searchData}
                  onChange={(e) => setSearchData(e.target.value)}
                  placeholder="Search by name"
                />
              </div>
            </div>
          </div>

          {loading ? (
            <h1 className="lod">
              <CircularProgress />
            </h1>
          ) : schools?.length === 0 ? (
            <h2 className="lod pt">No data available of common groups</h2>
          ) : (
            <div className="custom-data-table w-100">
              <Table responsive className="mb-0 px-4 pb-2">
                <thead>
                  <tr>
                    <th className="table-heading" width="20%">
                      Group Image
                    </th>

                    <th className="table-heading" width="50%">
                      Group Name
                    </th>

                    <th className="table-heading" width="20%">
                      Schools Count
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {schools?.map((group, i) => {
                    const { _id, count, lastImage } = group;

                    return (
                      <tr
                        key={_id}
                        className={i % 2 === 0 ? "even-row" : "odd-row"}
                      >
                        <td className="table-data" width="20%">
                          <div className="delete-group ">
                            <div className="d-flex align-items-center gap-2 text-decoration-none">
                              <Avatar
                                alt={_id}
                                src={lastImage}
                                sx={{ width: 32, height: 32 }}
                              />
                            </div>
                          </div>
                        </td>

                        <td className="table-data" width="50%">
                          <div className="delete-group ">{_id}</div>
                        </td>

                        <td className="table-data" width="20%">
                          {count}
                        </td>

                        <td className="table-data" width="10%">
                          <div className="delete-group d-flex align-items-center justify-content-end gap-3">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => onEditIconClick(_id, lastImage)}
                            >
                              <EditIcon />
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setOpenDeleteDialog(true);
                                setSchoolId(_id);
                              }}
                            >
                              <DeleteIcon />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>

      <Dialog open={openDeleteDialog} aria-labelledby="responsive-dialog-title">
        <DialogContent className="custom-delete-dialog">
          <div className="text-center">
            <DeleteDialogIcon />
          </div>
          <p className="heading mt-3">Delete School</p>
          <p className="data my-3">
            Are you sure you want to delete this Group from every School? This
            action cannot be undone.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="dialog-btn"
              style={{ color: "black" }}
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="dialog-btn"
              style={{ color: "white", background: "red", marginLeft: "20px" }}
              onClick={() => {
                setOpenDeleteDialog(false);
                deleteCommonGroups();
              }}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openEditDialog}
        onClose={handleEditClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent className="custom-delete-dialog">
          <p className="heading mx-auto mb-3">Update Common Groups</p>
          <Form>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">Group Name</Form.Label>
                <Form.Control
                  type="text"
                  required={true}
                  value={newName}
                  placeholder="Enter Group Name"
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Form.Group>

              {selectedImage ? (
                <div className="container mt-3 mb-3">
                  <img src={imageUrl} alt="Avatar" className="image" />
                  <div className="overlay">
                    <button className="icon" onClick={handleDeletFileClick}>
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-3">
                  <input
                    type="file"
                    id="file-input"
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    accept="image/jpeg, image/png, image/svg+xml"
                  />

                  <label htmlFor="file-input">
                    <UserIcon />
                  </label>
                </div>
              )}
            </div>

            {newName !== "" && selectedImage !== null && (
              <>
                <button
                  disabled={onSubmit}
                  className="custom-btn w-100"
                  onClick={updateCommonGroups}
                >
                  {onSubmit ? "Submitting..." : "Submit"}
                </button>

                {onSubmit && (
                  <p className="group-image-heading mt-3 text-danger">
                    It may require some time. So, please be patient
                  </p>
                )}
              </>
            )}
          </Form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={groupShow}
        onClose={handleCreateClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent className="custom-delete-dialog">
          <p className="heading mx-auto mb-3">Add Group In Every School</p>

          <Form>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">Group Name</Form.Label>
                <Form.Control
                  type="text"
                  required={true}
                  value={newName}
                  placeholder="Enter Name"
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Form.Group>

              {selectedImage ? (
                <div className="container mt-3 mb-3">
                  <img src={imageUrl} alt="Avatar" className="image" />
                  <div className="overlay">
                    <button className="icon" onClick={handleDeletFileClick}>
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-3">
                  <input
                    type="file"
                    id="file-input"
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    accept="image/jpeg, image/png, image/svg+xml"
                  />

                  <label htmlFor="file-input">
                    <UserIcon />
                  </label>
                </div>
              )}

              <p className="group-image-heading mt-2 mb-2">
                {selectedImage !== null ? selectedImage.name : "Add Image"}
              </p>

              {newName !== "" && selectedImage !== null && (
                <>
                  <button
                    disabled={onSubmit}
                    onClick={saveGroups}
                    className="custom-btn w-100"
                  >
                    {onSubmit ? "Submitting..." : "Submit"}
                  </button>

                  {onSubmit && (
                    <p className="group-image-heading mt-3 text-danger">
                      It may require some time. So, please be patient
                    </p>
                  )}
                </>
              )}
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default CommonGroups;
