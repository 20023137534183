import moment from "moment";
import Layout from "../../../Layout";
import localization from "moment/locale/en-in";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Table, Button, Form } from "react-bootstrap";
import { Dialog, DialogContent } from "@mui/material";
import {
  getCommonSuperlatives,
  postCommonSuperlatives,
  patchCommonSuperlatives,
  removeCommonSuperlatives,
} from "../../../controller/api";
import {
  EditIcon,
  SearchIcon,
  DeleteIcon,
  DeleteDialogIcon,
} from "../../../Icons";

const CommonSuperlatives = () => {
  const [schools, setSchools] = useState([]);
  const [newName, setNewName] = useState("");
  const [oldName, setOldName] = useState("");
  const [loading, setLoading] = useState(true);
  const [schoolId, setSchoolId] = useState("");
  const [onSubmit, setOnSubmit] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [superShow, setSuperShow] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  moment.updateLocale("en-in", localization);

  useEffect(() => {
    document.title = "Skoolfame | Common Superlatives";
  }, []);

  useEffect(() => {
    fetchCommonSuperlatives();
  }, [searchData]);

  const handleEditClose = () => {
    setNewName("");
    setOldName("");
    setOpenEditDialog(false);
  };

  const handleSuperShow = () => setSuperShow(true);

  const handleClose = () => {
    setNewName("");
    setSuperShow(false);
  };

  const fetchCommonSuperlatives = async () => {
    try {
      const commonSuperlatives = await getCommonSuperlatives(searchData);
      const { status, message, data } = commonSuperlatives;

      if (status === 1) {
        setSchools(data);
        setLoading(false);
      } else {
        toast.error(message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCommonSuperlatives = async () => {
    try {
      const res = await removeCommonSuperlatives(schoolId);

      if (res.status === 1) {
        toast.success(res.message);
        fetchCommonSuperlatives();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error, "school-page-error");
    }
  };

  const updateCommonSuperlatives = async () => {
    try {
      setOnSubmit(true);

      const res = await patchCommonSuperlatives(oldName, newName);

      if (res.status === 1) {
        setOnSubmit(false);
        toast.success(res.message);

        fetchCommonSuperlatives();
      } else {
        setOnSubmit(false);
        toast.error(res.message);
      }

      setOldName("");
      setNewName("");
      setOpenEditDialog(false);
    } catch (error) {
      console.log(error, "school-page-error");
    }
  };

  const saveSuperlatives = async () => {
    if (newName.trim().length === 0) {
      toast.error("Please enter name");
      return;
    }

    setOnSubmit(true);

    try {
      const res = await postCommonSuperlatives(newName);

      if (res.status === 1) {
        setNewName("");
        setSuperShow(false);
        setLoading(true);
        setOnSubmit(false);

        fetchCommonSuperlatives();
        toast.success(res.message);
      } else {
        setOnSubmit(false);
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="home-main">
        <div className="user-data w-100">
          <div className="school-main-heading d-flex justify-content-between align-items-center">
            Common Superlatives
            <Link onClick={handleSuperShow} style={{ textDecoration: "none" }}>
              <button className="custom-btn">Add Superlative</button>
            </Link>
          </div>
          <div className="school-data-heading w-100">
            <div className="d-flex flex-row-reverse">
              <div className="form-group has-search ms-2">
                <span className="form-control-feedback">
                  <SearchIcon />
                </span>
                <input
                  type="text"
                  value={searchData}
                  className="form-control"
                  placeholder="Search by name"
                  onChange={(e) => setSearchData(e.target.value)}
                />
              </div>
            </div>
          </div>

          {loading ? (
            <h1 className="lod">
              <CircularProgress />
            </h1>
          ) : schools?.length === 0 ? (
            <h2 className="lod pt">No data available of common superlatives</h2>
          ) : (
            <div className="custom-data-table w-100">
              <Table responsive className="mb-0 px-4 pb-2">
                <thead>
                  <tr>
                    <th className="table-heading" width="50%">
                      Name
                    </th>

                    <th className="table-heading" width="20%">
                      Schools Count
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {!loading &&
                    schools.length !== 0 &&
                    schools?.map((school, i) => {
                      const { _id, count } = school;

                      return (
                        <tr
                          key={_id}
                          className={i % 2 === 0 ? "even-row" : "odd-row"}
                        >
                          <td className="table-data" width="50%">
                            <div className="delete-group ">{_id}</div>
                          </td>

                          <td className="table-data" width="20%">
                            <div className="delete-group ">{count}</div>
                          </td>
                          <td className="table-data" width="10%">
                            <div className="delete-group d-flex align-items-center justify-content-end gap-3">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setNewName(_id);
                                  setOldName(_id);
                                  setOpenEditDialog(true);
                                }}
                              >
                                <EditIcon />
                              </span>

                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setOpenDeleteDialog(true);
                                  setSchoolId(_id);
                                }}
                              >
                                <DeleteIcon />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>

      <Dialog open={openDeleteDialog} aria-labelledby="responsive-dialog-title">
        <DialogContent className="custom-delete-dialog">
          <div className="text-center">
            <DeleteDialogIcon />
          </div>
          <p className="heading mt-3">Delete Superlative</p>
          <p className="data my-3">
            Are you sure you want to delete this Superlative from every School?
            This action cannot be undone.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="dialog-btn"
              style={{ color: "black" }}
              onClick={() => setOpenDeleteDialog(false)}
            >
              Cancel
            </Button>
            <Button
              className="dialog-btn"
              style={{ color: "white", background: "red", marginLeft: "20px" }}
              onClick={() => {
                setOpenDeleteDialog(false);
                deleteCommonSuperlatives();
              }}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openEditDialog}
        onClose={handleEditClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent className="custom-delete-dialog">
          <p className="heading mx-auto mb-3">Update Common Superlatives</p>
          <Form>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">
                  Superlative Name
                </Form.Label>
                <Form.Control
                  type="text"
                  required={true}
                  value={newName}
                  placeholder="Enter Superlative Name"
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Form.Group>
            </div>

            <button
              disabled={onSubmit}
              className="custom-btn w-100"
              onClick={updateCommonSuperlatives}
            >
              {onSubmit ? "Submitting..." : "Submit"}
            </button>

            {onSubmit && (
              <p className="group-image-heading mt-3 text-danger">
                It may require some time. So, please be patient
              </p>
            )}
          </Form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={superShow}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent className="custom-delete-dialog">
          <p className="heading mx-auto mb-3">Add Superlative In All schools</p>

          <Form>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newName}
                  required={true}
                  placeholder="Enter Name"
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Form.Group>
            </div>

            {newName && (
              <>
                <button
                  disabled={onSubmit}
                  className="custom-btn w-100"
                  onClick={saveSuperlatives}
                >
                  {onSubmit ? "Submitting..." : "Submit"}
                </button>

                {onSubmit && (
                  <p className="group-image-heading mt-3 text-danger">
                    It may require some time. So, please be patient
                  </p>
                )}
              </>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default CommonSuperlatives;
