import Sidebar from "./Components/sidebar/Sidebar";

import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const Layout = (props) => {
  // const [toggle, setToggle] = useState(false);
  const url = useLocation();

  return (
    <div>
      <Sidebar urls={url.pathname.split("/")[1]} />

      <main className="dashboard-main">
        {/* {
          <Header setToggle={setToggle} toggle={toggle} />
        } */}
        {/* <div className="toggle-btn" >
        <Button onClick={() => props.setToggle(!props.toggle)}><MdFilterList /></Button>
        </div> */}

        {props.children}
      </main>
    </div>
  );
};

export default Layout;
