import "./DeleteUserAccount.css";
import validator from "validator";
import CircularProgress from "@mui/material/CircularProgress";

import { useRef } from "react";
import { useEffect } from "react";
import { Logo } from "../../Icons";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteUserAccount } from "../../controller/api";

const DeleteUserAccount = () => {
  const ref = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    const callback = (event) => {
      if (event.key === "Enter") {
        ref.current.click();
      }
    };

    document.addEventListener("keydown", callback);
    document.title = "Skoolfame | Delete User Account";
    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, []);

  const handelSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!loginData.email.trim() || !loginData.password.trim()) {
        toast.error("Please fill field");
        setLoading(false);
        return;
      }

      if (!validator.isEmail(loginData.email.trim())) {
        toast.error("Please enter valid email address");
        setLoading(false);
        return;
      }

      const loginResponse = await deleteUserAccount(loginData);
      const { status, message } = loginResponse;

      if (status === 1) {
        toast.success(message);
        navigate("/");
      } else {
        setLoading(false);
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login d-flex align-items-center justify-content-center">
      <div className="login-box">
        <Logo />
        <div className="login-form">
          <label>Email or username</label>
          <input
            type="text"
            name="email"
            value={loginData.email}
            onChange={(e) =>
              setLoginData({
                ...loginData,
                [e.target.name]: e.target.value,
              })
            }
          />
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={loginData.password}
            onChange={(e) =>
              setLoginData({
                ...loginData,
                [e.target.name]: e.target.value,
              })
            }
          />

          <div className="d-flex justify-content-end"></div>
          <Button
            to="/dashboard"
            ref={ref}
            className="login-btn"
            onClick={handelSubmit}
            disabled={loading ? true : false}
          >
            {loading ? (
              <CircularProgress style={{ color: "white" }} />
            ) : (
              "Delete Account"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserAccount;
