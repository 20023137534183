import "../user/user.css";
import Avatar from "@mui/material/Avatar";
import Tooltip from "react-bootstrap/Tooltip";
import PaginationNew from "../PaginationNew";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import { Table, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { DeleteIcon, DeleteDialogIcon } from "../../Icons";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import {
  getHomeReportedPosts,
  removeHomeReportedPost,
} from "../../controller/api";

const perPage = 10;

const HomeReportedPosts = () => {
  const [postId, setPostId] = useState("");
  const [loading, setLoading] = useState(true);
  const [allReports, setAllReports] = useState([]);
  const [current_page, setCurrent_page] = useState(1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openReportsDialog, setOpenReportedDialog] = useState([]);

  useEffect(() => {
    fetchHomeReportedPosts();
    document.title = "Skoolfame | Home Reported Posts";
  }, []);

  const fetchHomeReportedPosts = async (pages) => {
    try {
      const res = await getHomeReportedPosts(perPage, pages);
      const {
        data,
        status,
        message,
        pagination_value,
        current_page: page,
      } = res;

      if (status === 1) {
        setAllReports(data);
        setCurrent_page(page);
        setPaginationVal(pagination_value);

        setLoading(false);
      } else {
        toast.error(message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error, "school-page-error");
    }
  };

  const deleteReportedPost = async () => {
    try {
      const res = await removeHomeReportedPost(postId);

      if (res.status === 1) {
        toast.success(res.message);
        fetchHomeReportedPosts();
        setPostId("");
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error, "school-page-error");
    }
  };

  return (
    <>
      {loading ? (
        <h1 className="lod">
          <CircularProgress />
        </h1>
      ) : allReports.length === 0 ? (
        <h2 className="lod">Currently, there are no reported posts</h2>
      ) : (
        <>
          <div className="custom-data-table">
            <Table responsive className="mb-0 px-4 pb-2">
              <thead>
                <tr>
                  <th className="table-heading" width="20%">
                    Posted User
                  </th>
                  <th className="table-heading" width="25%">
                    Reported By
                  </th>
                  <th className="table-heading" width="15%">
                    Reporters Count
                  </th>
                  <th className="table-heading" width="30%">
                    Post Reported
                  </th>
                </tr>
              </thead>
              <tbody>
                {allReports?.map((report, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "even-row" : "odd-row"}
                  >
                    <td className="table-data" width="20%">
                      <div className="d-flex align-items-center">
                        <Avatar
                          sx={{ width: 32, height: 32 }}
                          src={`${report.senderUser[0].user_profile_image}`}
                          alt={`${report.senderUser[0].first_name} ${report.senderUser[0].last_name}`}
                        />
                        <div className="ms-2">
                          <p className="user-name">
                            {`${
                              report.senderUser[0].first_name +
                              " " +
                              report.senderUser[0].last_name
                            }`}
                          </p>
                          <p className="user-email">
                            {report.senderUser[0].email}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td className="table-data" width="25%">
                      <div className="d-flex align-items-center">
                        {report.reportedUsers.map((reporter) => (
                          <OverlayTrigger
                            key={reporter._id}
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${reporter._id}`}>
                                {`${reporter.first_name} ${reporter.last_name}`}
                              </Tooltip>
                            }
                          >
                            <Avatar
                              sx={{
                                width: 32,
                                height: 32,
                                marginLeft: "5px",
                              }}
                              src={`${reporter.user_profile_image}`}
                              alt={`${reporter.first_name} ${reporter.last_name}`}
                            />
                          </OverlayTrigger>
                        ))}
                        {report.reportedUsers?.length > 0 && (
                          <Link
                            className="m-lg-2"
                            style={{
                              color: "gray",
                              textDecoration: "none",
                            }}
                            onClick={() =>
                              setOpenReportedDialog(report.reportedUsers)
                            }
                          >
                            <Launch />
                          </Link>
                        )}
                      </div>
                    </td>

                    <td className="table-data" width="15%">
                      {report.reportedUsers.length}
                    </td>

                    <td className="table-data" width="30%">
                      {report.postData?.message_type === "text" ? (
                        report.postData?.message
                      ) : report.postData?.message_type === "image" &&
                        report.postData?.images_list.length > 0 ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${report.postData.images_list[0]}`}
                        >
                          Open {report.postData?.message_type}
                        </a>
                      ) : (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${report.postData?.message}`}
                        >
                          Open {report.postData?.message_type}
                        </a>
                      )}
                    </td>

                    <td className="table-data" width="10%">
                      <div className="delete-group d-flex align-items-center justify-content-end gap-3">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenDeleteDialog(true);
                            setPostId(report.postData._id);
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-end mt-4 w-100">
            <PaginationNew
              pagination={paginationVal}
              current_page={current_page}
              AllUser={fetchHomeReportedPosts}
            />
          </div>
        </>
      )}

      <Dialog open={openDeleteDialog} aria-labelledby="responsive-dialog-title">
        <DialogContent className="custom-delete-dialog">
          <div className="text-center">
            <DeleteDialogIcon />
          </div>
          <p className="heading mt-3">Delete Home Reported Post</p>
          <p className="data my-3">
            Are you sure you want to delete this home post? This action cannot
            be undone.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="dialog-btn"
              style={{ color: "black" }}
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="dialog-btn"
              style={{ color: "white", background: "red", marginLeft: "20px" }}
              onClick={() => {
                setOpenDeleteDialog(false);
                deleteReportedPost();
              }}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={!!openReportsDialog.length}
        onClose={() => setOpenReportedDialog([])}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
          className="custom-delete-dialog"
          style={{ width: "600px" }}
        >
          <p className="heading mx-auto mb-3">Reporters Details</p>

          <div className="custom-data-table">
            <Table>
              <thead>
                <tr>
                  <th className="table-heading" width="25%">
                    Profile
                  </th>
                  <th className="table-heading" width="25%">
                    First Name
                  </th>
                  <th className="table-heading" width="25%">
                    Last Name
                  </th>
                  <th className="table-heading" width="25%">
                    Email
                  </th>
                </tr>
              </thead>

              <tbody>
                {openReportsDialog?.map((report, index) => (
                  <tr
                    key={report._id}
                    className={index % 2 === 0 ? "even-row" : "odd-row"}
                  >
                    <td className="table-data" width="25%">
                      <div className="d-flex align-items-center">
                        <Avatar
                          sx={{ width: 32, height: 32 }}
                          src={`${report.user_profile_image}`}
                          alt={`${report.first_name} ${report.last_name}`}
                        />
                      </div>
                    </td>

                    <td className="table-data" width="25%">
                      {report.first_name}
                    </td>
                    <td className="table-data" width="25%">
                      {report.last_name}
                    </td>
                    <td className="table-data" width="25%">
                      {report.email}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HomeReportedPosts;
