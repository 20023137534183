import axios from "axios";

const COMMON_ERROR = "Error, Please try again.!";

const errorResponse = (error) => {
  if (error.response) {
    const { status, data } = error.response;
    if (status === 403) {
      setTimeout(() => {
        window.location.href = "/";
        localStorage.clear();
      }, 5000);
    }
    return data;
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};

// LOGIN
export const login = async (requestData) => {
  try {
    const { email, password } = requestData;
    const login = await axios.post("/sign-in", { email, password });
    const { data } = login;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Delete USER ACCOUNT
export const deleteUserAccount = async (requestData) => {
  try {
    const { email, password } = requestData;
    const login = await axios.post("/delete-user-account", { email, password });
    const { data } = login;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// All USER
export const getAllUser = async (perPage, page = 1, searchData, rev) => {
  try {
    const AllUser = await axios.get(
      `/all-user?perPage=${perPage}&page=${page}&search=${searchData}&ascending=${rev}`
    );
    const { data } = AllUser;
    console.log("");
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Get USER
export const singleUserDetail = async (id) => {
  try {
    const userDetail = await axios.get(`/get-user-by-id?id=${id}`);
    const { data } = userDetail;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// All SCHOOL
export const getAllSchool = async (perPage, page = 1, searchData, rev) => {
  try {
    const AllSchool = await axios.get(
      `/get-all-school?perPage=${perPage}&page=${page}&search=${searchData}&ascending=${rev}`
    );
    const { data } = AllSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Get SCHOOL
export const getSingleSchool = async (id) => {
  try {
    const AllSchool = await axios.get(`/school-timeline/schools/${id}`);
    const { data } = AllSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Update SCHOOL
export const updateSingleSchool = async (formData, id) => {
  try {
    const CreateSchool = await axios.patch(
      `/school-timeline/schools/${id}`,
      formData
    );
    const { data } = CreateSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

///////////////////////////////////////////////////////////////Reported Post//////////////////////////////////////////////////////////

// To get all home reported posts...
export const getHomeReportedPosts = async (perPage, page = 1) => {
  try {
    const res = await axios.get(
      `/get-home-reported-posts?perPage=${perPage}&page=${page}`
    );

    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Delete home reported post base on id...
export const removeHomeReportedPost = async (id) => {
  try {
    const res = await axios.delete(`/delete-home-reported-post?id=${id}`);

    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

// To get all group reported posts...
export const getGroupReportedPosts = async (perPage, page = 1) => {
  try {
    const res = await axios.get(
      `/get-group-reported-posts?perPage=${perPage}&page=${page}`
    );

    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Delete group reported post base on id...
export const removeGroupReportedPost = async (id) => {
  try {
    const res = await axios.delete(`/delete-group-reported-post?id=${id}`);

    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

///////////////////////////////////////////////////////////////Reported User//////////////////////////////////////////////////////////

// All USER REPORTS
export const getAllUserReports = async (perPage, page = 1) => {
  //searchData
  try {
    const AllReport = await axios.get(
      `/report/all?perPage=${perPage}&page=${page}`
    ); //&search=${searchData}
    const { data } = AllReport;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const addWords = async (payload) => {
  try {
    // console.log(name,"=========Name==========");
    // return
    const AllSchool = await axios.post(`/moderators`, payload);
    const { data } = AllSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const uploadSchoolCSV = async (payload) => {
  try {
    // console.log(name,"=========Name==========");
    // return
    const formData = new FormData();
    formData.append("file", payload);
    const AllSchool = await axios.post(
      `/school-timeline/schools/import`,
      formData
    );
    const { data } = AllSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const uploadWordCSV = async (payload) => {
  try {
    // console.log(name,"=========Name==========");
    // return
    const formData = new FormData();
    formData.append("file", payload);
    const AllSchool = await axios.post(`/moderators/import`, formData);
    const { data } = AllSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const getAllWords = async (perPage, page = 1, searchData, rev) => {
  try {
    const AllSchool = await axios.get(
      `/moderators?perPage=${perPage}&page=${page}&search=${searchData}&ascending=${rev}`
    );
    const { data } = AllSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const addGroups = async (payload, schoolIconId) => {
  try {
    const res = await axios.post(
      `/school-timeline/school-groups/duplicate${schoolIconId !== null ? `?id=${schoolIconId}` : ""
      }`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const { data } = res;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

///////////////////////////////////////////////////////////////Schools Requests//////////////////////////////////////////////////////////

// Get all school with approval pending...
export const getNewSchoolsRequest = async (perPage, page = 1, searchData) => {
  try {
    const AllSchool = await axios.get(`/get-new-schools`);
    const { data } = AllSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const getUpdateSchoolRequests = async (
  perPage,
  page = 1,
  searchData
) => {
  try {
    const AllSchool = await axios.get(
      "/school-timeline/schools/change-requests"
    );
    const { data } = AllSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

//  SCHOOL
export const getSchool = async (perPage, page = 1, searchData, id, rev) => {
  try {
    const AllSchool = await axios.get(
      `/get-all-superlative?perPage=${perPage}&page=${page}&id=${id}&search=${searchData}&ascending=${rev}`
    );
    const { data } = AllSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

///////////////////////////////////////////////////////////////Superlatives//////////////////////////////////////////////////////////

// Add superlatives in specific school...
export const addSuperlatives = async (name, schoolIconId) => {
  try {
    const res = await axios.post(
      `/add-all-superlative${schoolIconId !== null ? `?id=${schoolIconId}` : ""
      }`,
      { name }
    );

    const { data } = res;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Get all superlatives group by category_name...
export const getCommonSuperlatives = async (search) => {
  try {
    const res = await axios.get(`/get-common-superlatives?search=${search}`);
    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Add all superlatives in every school group by category_name...
export const postCommonSuperlatives = async (name) => {
  try {
    const res = await axios.post("/add-common-superlatives", { name });

    const { data } = res;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Update all superlatives group by category_name...
export const patchCommonSuperlatives = async (oldName, newName) => {
  try {
    const res = await axios.post("/update-common-superlatives", {
      oldName,
      newName,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return errorResponse(error);
  }
};

// Delete all superlatives group by category_name...
export const removeCommonSuperlatives = async (categoryName) => {
  try {
    const res = await axios.delete(
      `/delete-common-superlatives?category_name=${categoryName}`
    );

    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

///////////////////////////////////////////////////////////////Groups//////////////////////////////////////////////////////////

export const getGroups = async (perPage, page = 1, searchData, id, rev) => {
  try {
    const AllSchool = await axios.get(
      `/school-timeline/groups?perPage=${perPage}&page=${page}&id=${id}&search=${searchData}&ascending=${rev}`
    );
    const { data } = AllSchool;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// To get all groups group by name...
export const getCommonGroups = async (search) => {
  try {
    const res = await axios.get(`/get-common-groups?search=${search}`);
    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Add all superlatives in every school group by category_name...
export const postCommonGroups = async (formData) => {
  try {
    const res = await axios.post("/add-common-groups", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Update all groups group by name...
export const patchCommonGroups = async (formData) => {
  try {
    const res = await axios.post("/update-common-groups", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return errorResponse(error);
  }
};

// Delete all groups group by name...
export const removeCommonGroups = async (name) => {
  try {
    const res = await axios.delete(`/delete-common-groups?name=${name}`);

    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const createSchool = async (formData) => {
  try {
    const res = await axios.post(`/add-school`, formData);
    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

///////////////////////////////////////////////////////////////About//////////////////////////////////////////////////////////

export const getAbout = async () => {
  try {
    const res = await axios.get("/about-us");
    return res.data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const updateAbout = async (about) => {
  try {
    const aboutDetail = await axios.post(`/about-us`, { message: about });
    const { data } = aboutDetail;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Get Album
export const getAlbum = async (type, id) => {
  try {
    const album = await axios.post(`/get-all-document?id=${id}`, { type });
    const { data } = album;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// user's list for chat
export const getChatuser = async (id) => {
  try {
    const user = await axios.get(`/get-user-chat?id=${id}`);
    const { data } = user;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// user chat
export const getChatById = async (sid, rid) => {
  try {
    const user = await axios.post("/user-chat", {
      sender_id: sid,
      receiver_id: rid,
    });
    const { data } = user;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// School Nominees
export const schoolNominees = async (id) => {
  try {
    const user = await axios.get(`/get-nominees?id=${id}`);
    const { data } = user;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// School MEMBERS
export const schoolMembers = async (perPage, page = 1, searchData, id) => {
  try {
    const user = await axios.get(
      `/school-timeline/groups/${id}/members?perPage=${perPage}&page=${page}&search=${searchData}`
    );
    const { data } = user;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Get dashboard data included live users...
export const getDashboardData = async (id) => {
  try {
    const user = await axios.get("/dashboard");
    const { data } = user;
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};
