import "./dashboard.css";
import moment from "moment";
import Layout from "../../Layout";
import Avatar from "@mui/material/Avatar";
import PaginationNew from "../PaginationNew";
import localization from "moment/locale/en-in";
import LaunchIcon from "@mui/icons-material/Launch";

import { useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { SearchIcon } from "../../Icons";
import { CircularProgress } from "@mui/material";
import { Col, Row, Table } from "react-bootstrap";
import { getDashboardData } from "../../controller/api";

const Dashboard = () => {
  const [allUser, setAllUser] = useState([]);
  const [allUserS, setAllUserS] = useState([]);
  const [allUserL, setAllUserL] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [pagination, setPagination] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrent_page] = useState(1);
  const [totalSchools, setTotalSchools] = useState(0);
  const [totalReportedUsers, setTotalReportedUsers] = useState(0);
  const [totalReportedPosts, setTotalReportedPosts] = useState({});
  const [totalSchoolRequests, setTotalSchoolRequests] = useState(0);
  const [totalChangeSchoolRequests, setTotalChangeSchoolRequests] = useState(0);

  const per_page = 10;

  moment.updateLocale("en-in", localization);

  const user = JSON.parse(localStorage.getItem("user") ?? null);

  useEffect(() => {
    fetchDashboardData();
    document.title = " Skoolfame | Dashboard";
  }, []);

  useEffect(() => {
    const searching = (e) => {
      setCurrent_page(1);

      if (searchData.length) {
        setAllUserL(
          allUserS
            .filter((user) =>
              `${user.user?.first_name + " " + user?.user?.last_name}`
                .toLowerCase()
                .includes(searchData)
            )
            .slice(0, per_page)
        );
        setPagination(
          allUserL
            ? Math.ceil(
                allUserS.filter((user) =>
                  `${user.user?.first_name + " " + user?.user?.last_name}`
                    .toLowerCase()
                    .includes(searchData)
                )?.length / per_page
              )
            : 1
        );
      } else {
        setAllUserL(allUser?.slice(currentPage - 1, per_page));
        setPagination(allUser ? Math.ceil(allUser?.length / per_page) : 1);
      }
    };
    searching();
  }, [searchData]);

  const AllFriends = (pages) => {
    if (pages === 1) {
      setAllUserL(
        searchData
          ? allUserS
              .filter((user) =>
                `${user.user?.first_name + " " + user?.user?.last_name}`
                  .toLowerCase()
                  .includes(searchData)
              )
              .slice(pages - 1, per_page)
          : allUser.slice(pages - 1, per_page)
      );
    } else {
      setAllUserL(
        searchData
          ? allUserS
              .filter((user) =>
                `${user.user?.first_name + " " + user?.user?.last_name}`
                  .toLowerCase()
                  .includes(searchData)
              )
              .slice(
                pages * per_page - per_page,
                pages * per_page - per_page + per_page
              )
          : allUser?.slice(
              pages * per_page - per_page,
              pages * per_page - per_page + per_page
            )
      );
    }
    setCurrent_page(pages);
  };

  const fetchDashboardData = async () => {
    setLoading(true);

    try {
      const res = await getDashboardData();

      if (res) {
        const {
          data,
          status,
          message,
          totalUsers,
          totalSchools,
          totalReportedUsers,
          totalReportedPosts,
          totalSchoolRequests,
          totalChangeSchoolRequests,
        } = res;

        if (status === 1) {
          setAllUser(data);
          setAllUserS(data);

          setPagination(data ? Math.ceil(data?.length / per_page) : 1);
          setAllUserL(() => data?.slice(currentPage - 1, per_page));

          setTotalUsers(totalUsers || 0);
          setTotalSchools(totalSchools || 0);
          setTotalReportedPosts(totalReportedPosts);
          setTotalReportedUsers(totalReportedUsers || 0);
          setTotalSchoolRequests(totalSchoolRequests || 0);
          setTotalChangeSchoolRequests(totalChangeSchoolRequests || 0);

          setLoading(false);
        } else {
          toast.error(message);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="home-main">
        <div className="dashboard-header">
          <p className="user-heading">
            Welcome back, {`${user.first_name} ${user.last_name}`}
          </p>
          <p className="user-text">
            Track, manage and forecast your schools and users
          </p>
        </div>

        <div className="cards-wrap border-top pt-3">
          <Row className="gx-3">
            <Col lg={4} md={6} sm={12}>
              <Link
                to="/users"
                style={{ textDecoration: "none" }}
                className="category-box d-flex justify-content-between bg-secondary p-3 rounded"
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h5 className="card-title text-white">Total Users</h5>
                  <LaunchIcon style={{ color: "white" }} />
                </div>
                <p className="card-count text-white">{totalUsers}</p>
              </Link>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <Link
                to="/schools"
                style={{ textDecoration: "none" }}
                className="category-box d-flex justify-content-between bg-secondary p-3 rounded"
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h5 className="card-title text-white">Total Schools</h5>
                  <LaunchIcon style={{ color: "white" }} />
                </div>
                <p className="card-count text-white">{totalSchools}</p>
              </Link>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <Link
                to="/request-schools"
                style={{ textDecoration: "none" }}
                className="category-box d-flex justify-content-between bg-secondary p-3 rounded"
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h5 className="card-title text-white">
                    Total New School Requests
                  </h5>
                  <LaunchIcon style={{ color: "white" }} />
                </div>
                <p className="card-count text-white">{totalSchoolRequests}</p>
              </Link>
            </Col>
          </Row>
        </div>

        <div className="cards-wrap pt-2">
          <Row className="gx-3">
            <Col lg={4} md={6} sm={12}>
              <Link
                to="/request-schools"
                style={{ textDecoration: "none" }}
                className="category-box d-flex justify-content-between bg-secondary p-3 rounded"
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h5 className="card-title text-white">
                    Total Update School Requests
                  </h5>
                  <LaunchIcon style={{ color: "white" }} />
                </div>
                <p className="card-count text-white">
                  {totalChangeSchoolRequests}
                </p>
              </Link>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <Link
                to="/reported-users"
                style={{ textDecoration: "none" }}
                className="category-box d-flex justify-content-between bg-secondary p-3 rounded"
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h5 className="card-title text-white">
                    Total Reported Users
                  </h5>
                  <LaunchIcon style={{ color: "white" }} />
                </div>
                <p className="card-count text-white">{totalReportedUsers}</p>
              </Link>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <div className="category-box d-flex justify-content-between bg-secondary p-3 rounded">
                <h5 className="card-title text-white">Live Users Count</h5>
                <p className="card-count text-white">{allUser?.length}</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="cards-wrap pt-2 border-bottom pb-3">
          <Row className="gx-3">
            <Col lg={4} md={6} sm={12}>
              <Link
                to="/reported-posts"
                style={{ textDecoration: "none" }}
                className="category-box d-flex justify-content-between bg-secondary p-3 rounded"
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h5 className="card-title text-white">
                    Total Reported Posts{" "}
                    {totalReportedPosts.homeReportedPost +
                      totalReportedPosts.groupReportedPost}
                  </h5>
                  <LaunchIcon style={{ color: "white" }} />
                </div>

                <div className="d-flex align-items-center justify-content-between w-100">
                  <h3 className="card-title text-white">
                    Home Posts {totalReportedPosts.homeReportedPost}
                  </h3>
                </div>

                <div className="d-flex align-items-center justify-content-between w-100">
                  <h3 className="card-title text-white">
                    Group Posts {totalReportedPosts.groupReportedPost}
                  </h3>
                </div>
              </Link>
            </Col>
          </Row>
        </div>

        <div className="users-wrap d-flex align-items-center justify-content-between">
          <p className="user-heading">Live Users</p>
          <div className="form-group has-search">
            <span className="form-control-feedback">
              <SearchIcon />
            </span>
            <input
              className="form-control"
              type="text"
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search"
            />
          </div>
        </div>

        {loading ? (
          <div className="lod">
            <CircularProgress />
          </div>
        ) : allUserL?.length === 0 ? (
          <h2 className="lod">Currently no users are live</h2>
        ) : (
          <>
            <div className="custom-data-table">
              <Table responsive className="mb-0 px-4 pb-2">
                <thead>
                  <tr>
                    <th className="table-heading" width="60%">
                      Name
                    </th>
                    <th className="table-heading" width="20%">
                      Time
                    </th>
                    <th className="table-heading" width="20%">
                      Views
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {allUserL?.map((u, i) => {
                    return (
                      <tr
                        key={i}
                        className={i % 2 === 0 ? "even-row" : "odd-row"}
                      >
                        <td className="table-data" width="60%">
                          <div className="d-flex align-items-center">
                            <Avatar
                              alt="user profile"
                              src={
                                u.user?.user_profile_image
                                  ? u.user?.user_profile_image
                                  : "../images/user.png"
                              }
                              sx={{ width: 32, height: 32 }}
                            />
                            <div className="ms-2">
                              <p className="user-name">
                                {`${
                                  u.user?.first_name + " " + u.user?.last_name
                                }`}
                              </p>
                              <p className="user-email">{u.user?.email}</p>
                            </div>
                          </div>
                        </td>
                        <td className="table-data" width="20%">
                          {moment(u?.user?.live_time).format("LT")}
                        </td>
                        <td className="table-data" width="20%">
                          {u?.join_user?.length ? u?.join_user?.length : 0}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            <div className="d-flex justify-content-end mt-4 w-100">
              <PaginationNew
                AllUser={AllFriends}
                pagination={pagination}
                current_page={currentPage}
              />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Dashboard;
