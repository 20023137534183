import axios from "axios";
import moment from "moment";
import Layout from "../../../Layout";
import localization from "moment/locale/en-in";
import PaginationNew from "../../PaginationNew";

import { toast } from "react-toastify";
import { Table, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getGroups } from "../../../controller/api";
import { Dialog, DialogContent, Avatar, CircularProgress } from "@mui/material";
import {
  SearchIcon,
  DeleteIcon,
  SortingIcon,
  DeleteDialogIcon,
} from "../../../Icons";

const Groups = () => {
  const [rev, setRev] = useState(false);
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [schoolId, setSchoolId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [current_page, setCurrent_page] = useState(1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { id } = useParams();

  const perPage = 5;

  moment.updateLocale("en-in", localization);

  useEffect(() => {
    document.title = "Skoolfame | Superlatives";
  }, []);

  useEffect(() => {
    SchoolDetails();
  }, [searchData, rev]);

  const SchoolDetails = async (pages) => {
    try {
      const SchoolData = await getGroups(perPage, pages, searchData, id, rev);
      const { status, message, data, count, pagination_value, current_page } =
        SchoolData;
      if (status === 1) {
        setSchools(data);
        setPaginationVal(pagination_value);
        setCurrent_page(current_page);
        setLoading(false);
      } else {
        toast.error(message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rever = () => setRev(!rev);

  const deletSchool = async () => {
    if (schoolId !== "") {
      try {
        const res = await axios.delete(
          `/school-timeline/school-groups/delete-group?id=${schoolId}`
        );
        if (res.data.status === 1) {
          toast.success(res.data.message);
          SchoolDetails(current_page);
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.log(error, "school-page-error");
      }
    }
  };

  return (
    <Layout>
      <div className="home-main">
        <div className="user-data w-100">
          <div className="school-main-heading d-flex justify-content-between align-items-center">
            Groups
          </div>
          <div className="school-data-heading w-100">
            <div className="d-flex flex-row-reverse">
              <div className="form-group has-search ms-2">
                <span className="form-control-feedback">
                  <SearchIcon />
                </span>
                <input
                  className="form-control"
                  type="text"
                  value={searchData}
                  onChange={(e) => setSearchData(e.target.value)}
                  placeholder="Search by name"
                />
              </div>
            </div>
          </div>

          {loading ? (
            <h1 className="lod">
              <CircularProgress />
            </h1>
          ) : schools?.length === 0 ? (
            <h1 className="lod">No data available of groups</h1>
          ) : (
            <>
              <div className="custom-data-table w-100">
                <Table responsive className="mb-0 px-4 pb-2">
                  <thead>
                    <tr>
                      <th className="table-heading" width="10%">
                        Group Image
                      </th>
                      <th className="table-heading" width="25%">
                        Group Name
                      </th>
                      <th className="table-heading" width="15%">
                        Member
                      </th>
                      <th className="table-heading" width="15%">
                        School Name
                      </th>
                      <th className="table-heading" width="45%">
                        Created At
                        <span
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={rever}
                        >
                          <SortingIcon />
                        </span>
                      </th>
                      {/* <th className="table-heading" width="19%"> */}
                      {/* <span className="d-flex align-items-center justify-content-end px-5">
                          <Button  onClick={handleShow}  className="btn-plus shadow-none">+</Button>
                        </span> */}
                      {/* </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      schools.length !== 0 &&
                      schools?.map((group, i) => {
                        const { _id, members, school, createdAt, name, image } =
                          group;
                        return (
                          <tr
                            key={_id}
                            className={i % 2 === 0 ? "even-row" : "odd-row"}
                          >
                            <td className="table-data" width="10%">
                              <div className="delete-group ">
                                <div className="d-flex align-items-center gap-2 text-decoration-none">
                                  <Avatar
                                    alt={name}
                                    src={image}
                                    sx={{ width: 32, height: 32 }}
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="table-data" width="25%">
                              <div className="delete-group ">{name}</div>
                            </td>
                            <td className="table-data" width="15%">
                              {members?.length}
                            </td>
                            <td className="table-data" width="15%">
                              {school.name}
                            </td>
                            <td className="table-data" width="15%">
                              {moment(createdAt).format("L")}
                            </td>
                            <td className="table-data" width="20%">
                              <div className="nominees-btn">
                                <Link
                                  to={`/members/${_id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "#000",
                                  }}
                                >
                                  View Members
                                </Link>
                              </div>
                            </td>
                            <td className="table-data" width="10%">
                              <div className="delete-group d-flex align-items-center justify-content-end gap-3">
                                {/* <Link
                                  to={`/Superlatives/${_id}`}
                                  className="d-flex align-items-center gap-3 p-0 text-decoration-none">
                                  <Button>Info</Button>
                                </Link> */}
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOpenDeleteDialog(true);
                                    setSchoolId(_id);
                                  }}
                                >
                                  <DeleteIcon />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>

              <div className="d-flex justify-content-end mt-4">
                <PaginationNew
                  AllUser={SchoolDetails}
                  pagination={paginationVal}
                  current_page={current_page}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <Dialog open={openDeleteDialog} aria-labelledby="responsive-dialog-title">
        <DialogContent className="custom-delete-dialog">
          <div className="text-center">
            <DeleteDialogIcon />
          </div>
          <p className="heading mt-3">Delete Group</p>
          <p className="data my-3">
            Are you sure you want to delete this Group? This action cannot be
            undone.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="dialog-btn"
              style={{ color: "black" }}
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="dialog-btn"
              style={{ color: "white", background: "red", marginLeft: "20px" }}
              onClick={() => {
                setOpenDeleteDialog(false);
                deletSchool();
              }}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default Groups;
