import "./addSchool.css";
import Layout from "../../../Layout";
import statesData from "./country.json";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import React, { useState, useRef } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { UploadIcon, DeleteIcon } from "../../../Icons";
import { createSchool, uploadSchoolCSV } from "../../../controller/api";

const AddSchool = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [school, setSchool] = useState({
    name: "",
    state: "",
    about: "",
    since: "",
    address: "",
    schoolType: "",
  });

  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [onSubmit, setOnSubmit] = useState(false);
  const [selectEduLevel, setSelectEduLevel] = useState("");
  const [selectedStateAbbr, setSelectedStateAbbr] = useState("");

  const levels = [
    { label: "High School", value: "High School" },
    { label: "Middle School", value: "Middle School" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "state") {
      setSelectedStateAbbr(value);
    }

    if (name === "schoolType") {
      setSelectEduLevel(value);
    }

    setSchool({ ...school, [name]: value });
  };

  const handleFileInput = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };

      reader.readAsDataURL(uploadedFile);
    }
  };

  const handleCloseClick = () => {
    setFile(null);
    setImageUrl("");
  };

  const handleClick = async () => {
    if (school.name === "") {
      toast.error("Please enter the school name");
      return;
    } else if (school.address === "") {
      toast.error("Please enter the school address");
      return;
    }

    setOnSubmit(true);

    const formData = new FormData();

    formData.append("file", file);
    formData.append("name", school.name);
    formData.append("state", school.state);
    formData.append("since", school.since);
    formData.append("about", school.about);
    formData.append("address", school.address);
    formData.append("schoolType", school.schoolType);

    const res = await createSchool(formData);

    if (res) {
      const { message, status } = res;
      if (status === 1) {
        navigate("/schools");
        toast.success(message);
      } else {
        toast.error(message);
      }
    }

    setOnSubmit(false);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.type === "text/csv" && selectedFile.size <= 30000000) {
        const createdData = await uploadSchoolCSV(selectedFile);
        if (createdData) {
          const { message, status } = createdData;
          if (status === 1) {
            navigate("/schools");
            toast.success(message);
          } else {
            toast.error(message);
          }
        }
        console.log("Selected file:", selectedFile.name);
      } else {
        alert("Please select a CSV file with a maximum size of 30MB.");
        e.target.value = "";
      }
    }
  };

  return (
    <Layout>
      <div className="home-main" style={{ padding: "32px" }}>
        <div className="school-creation-main-heading d-flex justify-content-between align-items-center w-100">
          Create New School
          <div>
            <button onClick={handleButtonClick} className="custom-btn">
              Upload CSV
            </button>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>
        <p className="create-school-text mt-3">Create School</p>

        <Row className="form-wrap">
          <Col lg={8} md={8} sm={12}>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">Education Level</Form.Label>
                <Form.Select
                  name="schoolType"
                  value={selectEduLevel}
                  onChange={handleChange}
                >
                  <option value="">Select Education Level</option>
                  {levels.map((level) => (
                    <option key={level.value} value={level.value}>
                      {level.value}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="custom-input" style={{ width: "48%" }}>
                <Form.Group controlId="formGridEmail">
                  <Form.Label className="title-lable">School Name *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="custom-input " style={{ width: "48%" }}>
                <Form.Group controlId="formGridEmail">
                  <Form.Label className="title-lable">Since Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="since"
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">Address *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="address"
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">State</Form.Label>
                <Form.Select
                  name="state"
                  value={selectedStateAbbr}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {statesData.map((state) => (
                    <option key={state.abbreviation} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">About</Form.Label>
                <Form.Control
                  type="text"
                  name="about"
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            {file ? (
              <div className="container">
                <img src={imageUrl} alt="Avatar" className="image" />
                <div className="overlay">
                  <button className="icon" onClick={handleCloseClick}>
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between">
                <div className="custom-file">
                  <input
                    required
                    type="file"
                    name="file"
                    id="field-upload"
                    onChange={handleFileInput}
                    className="custom-file__input"
                  />
                  <label className="custom-file__label" htmlFor="field-upload">
                    <div>
                      <span className="mt-2">
                        <UploadIcon />
                      </span>
                      <div className="upload-text mt-2">
                        <span style={{ color: "#6941C6", fontWeight: "bold" }}>
                          Click to upload{" "}
                        </span>
                        <span>or drag and drop</span>
                      </div>
                      <div className="upload-text">
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <button variant="primary" className="custom-cancel-btn me-3">
                Cancel
              </button>

              <button
                disabled={onSubmit}
                onClick={handleClick}
                className="custom-btn"
              >
                {onSubmit ? "Submitting..." : "Submit"}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default AddSchool;
