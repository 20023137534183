import "./procedure.css";
import React from "react";
import Layout from "../../Layout";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { updateAbout, getAbout } from "../../controller/api";

const Procedure = () => {
  const [updateAboutUs, setUpdateAboutUs] = useState("");
  const [loading, setLoading] = useState(true);
  const [aboutUs, setAboutUs] = useState("");

  useEffect(() => {
    document.title = "Skoolfame | About";

    fetchAboutUs();
  }, []);

  const fetchAboutUs = async () => {
    try {
      const commonSuperlatives = await getAbout();
      const { status, message, data } = commonSuperlatives;

      if (status === 1) {
        setAboutUs(data);
        setLoading(false);
      } else {
        toast.error(message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handle = async () => {
    if (updateAboutUs.trim().length === 0) {
      toast.error("You can not update empty content");
      setUpdateAboutUs("");
      return;
    }
    try {
      const res = await updateAbout(updateAboutUs);

      const { status, message } = res;

      if (status === 1) {
        setLoading(true);
        fetchAboutUs();
        setUpdateAboutUs("");
        toast.success(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="home-main">
        <div className="about-main-heading">About</div>

        {loading ? (
          <h1 className="lod">
            <CircularProgress />
          </h1>
        ) : (
          <>
            <div className="current-about">
              <h5>Current About Us</h5>
              <p className="text-secondary">{aboutUs}</p>
            </div>

            <TextareaAutosize
              value={updateAboutUs}
              aria-label="empty textarea"
              className="about-teaxtarea area "
              placeholder="Enter the description..."
              onChange={(e) => setUpdateAboutUs(e.target.value)}
            />

            <div className="w-100">
              <Button className="about-btn" onClick={handle}>
                Update
              </Button>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Procedure;
