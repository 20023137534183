import "./support.css";
import React from "react";

function Support() {
  return (
    <div className="body-bg">
      <h1 className="text-center heading-bg">Skoolfame</h1>
      <div className="support-container">
        <h1 className="support">Support</h1>
        <h3 className="contact">Please contact us here for any queries:</h3>
        <div className="email-at">
          <a href="mailto:Toddhmo@yahoo.com" className="email-link">
            Toddhmo@yahoo.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default Support;
