import "../AddSchool/addSchool.css";
import Layout from "../../../Layout";
import statesData from "../AddSchool/country.json";

import { toast } from "react-toastify";
import { Form, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { UploadIcon, DeleteIcon } from "../../../Icons";
import { useParams, useNavigate } from "react-router-dom";
import { getSingleSchool, updateSingleSchool } from "../../../controller/api";

const AddSchool = () => {
  const navigate = useNavigate();

  const [school, setSchool] = useState({
    name: "",
    since: "",
    about: "",
    state: "",
    address: "",
    schoolType: "",
  });

  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [onSubmit, setOnSubmit] = useState(false);
  const [imageUpdated, setImageUpdated] = useState(false);
  const [selectEduLevel, setSelectEduLevel] = useState("");
  const [selectedStateAbbr, setSelectedStateAbbr] = useState("");

  const { id } = useParams();

  const getSchool = async () => {
    try {
      const data = await getSingleSchool(id);

      const obj = {
        name: data.data.name || "",
        since: data.data.since || "",
        state: data.data.state || "",
        about: data.data.about || "",
        address: data.data.address || "",
        schoolType: data.data.schoolType || "",
      };

      setSchool(obj);

      if (data.data.schoolType) {
        setSelectEduLevel(data.data.schoolType);
      }

      if (data.data.state) {
        setSelectedStateAbbr(data.data.state);
      }

      const { image } = data.data;

      if (image) {
        setImageUrl(image);
        setFile(image);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchool();
  }, []);

  const levels = [
    { label: "High School", value: "High School" },
    { label: "Middle School", value: "Middle School" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the changed input is the state field, update state abbreviation and full name
    if (name === "state") {
      setSelectedStateAbbr(value);
    }
    if (name === "schoolType") {
      setSelectEduLevel(value);
    }

    setSchool({ ...school, [name]: value });
  };

  const handleFileInput = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    setImageUpdated(true);

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  const handleClick = async () => {
    if (school.name === "") {
      toast.error("Please enter the school name");
      return;
    } else if (school.address === "") {
      toast.error("Please enter the school address");
      return;
    }

    setOnSubmit(true);

    const formData = new FormData();

    formData.append("file", file);
    formData.append("name", school.name);
    formData.append("state", school.state);
    formData.append("since", school.since);
    formData.append("about", school.about);
    formData.append("address", school.address);
    formData.append("imageUpdated", imageUpdated);
    formData.append("schoolType", school.schoolType);

    const res = await updateSingleSchool(formData, id);

    if (res) {
      const { message, status } = res;
      if (status === 1) {
        navigate("/schools");
        toast.success(message);
      } else {
        toast.error(message);
      }
    }

    setOnSubmit(false);
  };

  const handleDeleteFileClick = () => {
    setFile(null);
    setImageUrl("");

    setImageUpdated(true);
  };

  return (
    <Layout>
      <div className="home-main" style={{ padding: "32px" }}>
        <div className="school-creation-main-heading d-flex justify-content-between align-items-center w-100">
          Update School
        </div>
        <Row className="form-wrap">
          <Col lg={8} md={8} sm={12}>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">Education Level</Form.Label>
                <Form.Select
                  name="schoolType"
                  value={selectEduLevel}
                  onChange={handleChange}
                >
                  <option value="">Select Education Level</option>
                  {levels.map((level) => (
                    <option key={level.value} value={level.value}>
                      {level.value}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="custom-input" style={{ width: "48%" }}>
                <Form.Group controlId="formGridEmail">
                  <Form.Label className="title-lable">School Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={school.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="custom-input " style={{ width: "48%" }}>
                <Form.Group controlId="formGridEmail">
                  <Form.Label className="title-lable">Since Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="since"
                    value={school.since}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">Address *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="address"
                  value={school.address}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">State</Form.Label>
                <Form.Select
                  name="state"
                  value={selectedStateAbbr}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {statesData.map((state) => (
                    <option key={state.abbreviation} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="custom-input">
              <Form.Group controlId="formGridEmail">
                <Form.Label className="title-lable">About</Form.Label>
                <Form.Control
                  type="text"
                  name="about"
                  value={school.about}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            {file ? (
              <div className="container">
                <img src={imageUrl} alt="Avatar" className="image" />
                <div className="overlay">
                  <button className="icon" onClick={handleDeleteFileClick}>
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file__input"
                    id="field-upload"
                    name="file"
                    onChange={handleFileInput}
                    required
                  />
                  <label className="custom-file__label" for="field-upload">
                    <div>
                      <span className="mt-2">
                        <UploadIcon />
                      </span>
                      <div className="upload-text mt-2">
                        <span style={{ color: "#6941C6", fontWeight: "bold" }}>
                          Click to upload{" "}
                        </span>
                        <span>or drag and drop</span>
                      </div>
                      <div className="upload-text">
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            )}
            <div className="d-flex align-items-center justify-content-end mt-3">
              <button
                variant="primary"
                onClick={() => navigate("/schools")}
                className="custom-cancel-btn me-3"
              >
                Cancel
              </button>

              <button
                disabled={onSubmit}
                onClick={handleClick}
                className="custom-btn"
              >
                {onSubmit ? "Updating..." : "Update"}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default AddSchool;
