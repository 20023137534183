import Layout from "../../Layout";
import Avatar from "@mui/material/Avatar";
import Tooltip from "react-bootstrap/Tooltip";
import PaginationNew from "../PaginationNew";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { getAllUserReports } from "../../controller/api";

const perPage = 10;

const ReportedUsers = () => {
  const [loading, setLoading] = useState(true);
  const [allReports, setAllReports] = useState([]);
  const [current_page, setCurrent_page] = useState(1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [openReportsDialog, setOpenReportedDialog] = useState([]);

  const AllReports = async (pages) => {
    try {
      const userData = await getAllUserReports(perPage, pages);

      const {
        status,
        message,
        reports,
        pagination_value,
        current_page: page,
      } = userData;

      if (status === 1) {
        setAllReports(reports);
        setPaginationVal(pagination_value);
        setCurrent_page(page);
        setLoading(false);
      } else {
        toast.error(message);
        setLoading(false);
      }
    } catch (error) {
      console.error(error, "user-report-page-error");
    }
  };

  useEffect(() => {
    AllReports();
    document.title = "Skoolfame | User Reports";
  }, []);

  return (
    <Layout>
      <div className="home-main">
        <div className="user-main-heading">Reported Users</div>

        {loading ? (
          <h1 className="lod">
            <CircularProgress />
          </h1>
        ) : allReports.length === 0 ? (
          <h2 className="lod">Currently, there are no reported users</h2>
        ) : (
          <>
            <div className="custom-data-table">
              <Table responsive className="mb-0 px-4 pb-2">
                <thead>
                  <tr>
                    <th className="table-heading" width="33%">
                      Reported User
                    </th>
                    <th className="table-heading" width="33%">
                      Reported By
                    </th>
                    <th className="table-heading" width="34%">
                      Reporters Count
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {allReports.map((report, index) => (
                    <tr
                      key={report._id}
                      className={index % 2 === 0 ? "even-row" : "odd-row"}
                    >
                      <td className="table-data" width="33%">
                        <div className="d-flex align-items-center">
                          <Avatar
                            alt={`${report.reported_user.first_name} ${report.reported_user.last_name}`}
                            src={`${report.reported_user.user_profile_image}`}
                            sx={{ width: 32, height: 32 }}
                          />
                          <div className="ms-2">
                            <p className="user-name">
                              {`${
                                report.reported_user.first_name +
                                " " +
                                report.reported_user.last_name
                              }`}
                            </p>
                            <p className="user-email">
                              {report.reported_user.email}
                            </p>
                          </div>
                        </div>
                      </td>

                      <td className="table-data" width="33%">
                        <div className="d-flex align-items-center">
                          {report.reporters.map((reporter) => (
                            <OverlayTrigger
                              key={reporter._id}
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-${reporter._id}`}>
                                  {`${reporter.first_name} ${reporter.last_name}`}
                                </Tooltip>
                              }
                            >
                              <Avatar
                                src={`${reporter.user_profile_image}`}
                                alt={`${reporter.first_name} ${reporter.last_name}`}
                                sx={{
                                  width: 32,
                                  height: 32,
                                  marginLeft: "5px",
                                }}
                              />
                            </OverlayTrigger>
                          ))}
                          {report.reporters.length > 0 && (
                            <Link
                              className="m-lg-2"
                              style={{
                                color: "gray",
                                textDecoration: "none",
                              }}
                              onClick={() =>
                                setOpenReportedDialog(report.reporters)
                              }
                            >
                              <Launch />
                            </Link>
                          )}
                        </div>
                      </td>
                      <td className="table-data" width="34%">
                        {report.reporters.length}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="d-flex justify-content-end mt-4 w-100">
              <PaginationNew
                AllUser={AllReports}
                pagination={paginationVal}
                current_page={current_page}
              />
            </div>
          </>
        )}
      </div>

      <Dialog
        open={!!openReportsDialog.length}
        onClose={() => setOpenReportedDialog([])}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
          className="custom-delete-dialog"
          style={{ width: "600px" }}
        >
          <p className="heading mx-auto mb-3">Reporters Details</p>

          <div className="custom-data-table">
            <Table>
              <thead>
                <tr>
                  <th className="table-heading" width="25%">
                    Profile
                  </th>
                  <th className="table-heading" width="25%">
                    First Name
                  </th>
                  <th className="table-heading" width="25%">
                    Last Name
                  </th>
                  <th className="table-heading" width="25%">
                    Email
                  </th>
                </tr>
              </thead>

              <tbody>
                {openReportsDialog?.map((report, index) => (
                  <tr
                    key={report._id}
                    className={index % 2 === 0 ? "even-row" : "odd-row"}
                  >
                    <td className="table-data" width="25%">
                      <div className="d-flex align-items-center">
                        <Avatar
                          sx={{ width: 32, height: 32 }}
                          src={`${report.user_profile_image}`}
                          alt={`${report.first_name} ${report.last_name}`}
                        />
                      </div>
                    </td>

                    <td className="table-data" width="25%">
                      {report.first_name}
                    </td>
                    <td className="table-data" width="25%">
                      {report.last_name}
                    </td>
                    <td className="table-data" width="25%">
                      {report.email}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default ReportedUsers;
