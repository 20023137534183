import axios from "axios";
import moment from "moment";
import Layout from "../../../Layout";
import localization from "moment/locale/en-in";
import PaginationNew from "../../PaginationNew";

import { toast } from "react-toastify";
import { Table, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getSchool } from "../../../controller/api";
import { Dialog, DialogContent, CircularProgress } from "@mui/material";
import {
  SearchIcon,
  DeleteIcon,
  SortingIcon,
  DeleteDialogIcon,
} from "../../../Icons";

const Superlatives = () => {
  const [rev, setRev] = useState(false);
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [schoolId, setSchoolId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [current_page, setCurrent_page] = useState(1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { id } = useParams();

  const perPage = 5;

  moment.updateLocale("en-in", localization);

  useEffect(() => {
    SchoolDetails();
  }, [searchData, rev]);

  useEffect(() => {
    document.title = "Skoolfame | Superlatives";
  }, []);

  const rever = () => setRev(!rev);

  const deletSchool = async () => {
    if (schoolId !== "") {
      try {
        const res = await axios.delete(
          `/school-timeline/school-superlatives/delete-superlative?id=${schoolId}`
        );
        if (res.data.status === 1) {
          toast.success(res.data.message);
          SchoolDetails(current_page);
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.log(error, "school-page-error");
      }
    }
  };

  const SchoolDetails = async (pages) => {
    try {
      const SchoolData = await getSchool(perPage, pages, searchData, id, rev);
      const { status, message, data, count, paginationValue, page } =
        SchoolData;
      if (status === 1) {
        setSchools(data);
        setPaginationVal(paginationValue);
        setCurrent_page(page);
        setLoading(false);
      } else {
        toast.error(message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="home-main">
        <div className="user-data w-100">
          <div className="school-main-heading d-flex justify-content-between align-items-center">
            Superlative
          </div>
          <div className="school-data-heading w-100">
            <div className="d-flex flex-row-reverse">
              <div className="form-group has-search ms-2">
                <span className="form-control-feedback">
                  <SearchIcon />
                </span>
                <input
                  className="form-control"
                  type="text"
                  value={searchData}
                  onChange={(e) => setSearchData(e.target.value)}
                  placeholder="Search by name"
                />
              </div>
            </div>
          </div>

          {loading ? (
            <h1 className="lod">
              <CircularProgress />
            </h1>
          ) : schools?.length === 0 ? (
            <h1 className="lod">No data available of superlatives</h1>
          ) : (
            <>
              <div className="custom-data-table w-100">
                <Table responsive className="mb-0 px-4 pb-2">
                  <thead>
                    <tr>
                      <th className="table-heading" width="40%">
                        Name
                      </th>
                      <th className="table-heading" width="15%">
                        Nominees
                      </th>
                      <th className="table-heading" width="45%">
                        Created At
                        <span
                          style={{ marginLeft: "2px", cursor: "pointer" }}
                          onClick={rever}
                        >
                          <SortingIcon />
                        </span>
                      </th>
                      {/* <th className="table-heading" width="19%"> */}
                      {/* <span className="d-flex align-items-center justify-content-end px-5">
                          <Button  onClick={handleShow}  className="btn-plus shadow-none">+</Button>
                        </span> */}
                      {/* </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      schools.length !== 0 &&
                      schools?.map((school, i) => {
                        const {
                          _id,
                          user_profile_image,
                          name,
                          createdAt,
                          users,
                          category_name,
                        } = school;
                        return (
                          <tr
                            key={_id}
                            className={i % 2 === 0 ? "even-row" : "odd-row"}
                          >
                            <td className="table-data" width="40%">
                              <div className="delete-group ">
                                {category_name}
                              </div>
                            </td>
                            <td className="table-data" width="15%">
                              {users?.length}
                            </td>
                            <td className="table-data" width="15%">
                              {moment(createdAt).format("L")}
                            </td>
                            <td className="table-data" width="20%">
                              <div className="nominees-btn">
                                <Link
                                  to={`/nominees/${_id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "#000",
                                  }}
                                >
                                  View Nominees
                                </Link>
                              </div>
                            </td>
                            <td className="table-data" width="10%">
                              <div className="delete-group d-flex align-items-center justify-content-end gap-3">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOpenDeleteDialog(true);
                                    setSchoolId(_id);
                                  }}
                                >
                                  <DeleteIcon />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>

              <div className="d-flex justify-content-end mt-4">
                <PaginationNew
                  AllUser={SchoolDetails}
                  pagination={paginationVal}
                  current_page={current_page}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <Dialog open={openDeleteDialog} aria-labelledby="responsive-dialog-title">
        <DialogContent className="custom-delete-dialog">
          <div className="text-center">
            <DeleteDialogIcon />
          </div>
          <p className="heading mt-3">Delete Superlative</p>
          <p className="data my-3">
            Are you sure you want to delete this Superlative? This action cannot
            be undone.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="dialog-btn"
              style={{ color: "black" }}
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="dialog-btn"
              style={{ color: "white", background: "red", marginLeft: "20px" }}
              onClick={() => {
                setOpenDeleteDialog(false);
                deletSchool();
              }}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default Superlatives;
