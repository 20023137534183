import "./App.css";
import axios from "axios";
import Chat from "./Components/Chat/Chat";
import User from "./Components/user/User";
import Login from "./Components/login/Login";
import Terms from "./Components/Terms/Terms";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import About from "./Components/procedure/About";
import Profile from "./Components/Profile/Profile";
import Support from "./Components/Support/Support";
import Notfound from "./Components/notfound/Notfound";
import Groups from "./Components/school/Groups/Groups";
import Dashboard from "./Components/Dashboard/Dashboard";
import Moderator from "./Components/moderator/Moderator";
import Schools from "./Components/school/schools/Schools";
import Members from "./Components/school/Members/Members";
import Nominees from "./Components/school/Nominees/Nominees";
import AddSchool from "./Components/school/AddSchool/AddSchool";
import CommonGroups from "./Components/school/Groups/CommonGroups";
import EditSchool from "./Components/school/EditSchool/EditSchool";
import UserDetails from "./Components/user/UserDetails/UserDetails";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import ReportedUsers from "./Components/ReportedUsers/ReportedUsers";
import ReportedPosts from "./Components/ReportedPosts/ReportedPosts";
import Superlatives from "./Components/school/Superlatives/Superlatives";
import RequestTabs from "./Components/school/School_Requests/RequestTabs";
import DeleteUserAccount from "./Components/DeleteUserAccount/DeleteUserAccount";
import CommonSuperlatives from "./Components/school/Superlatives/CommonSuperlatives";
import UserLicenseAgreement from "./Components/UserLicenseAgreement/UserLicenseAgreement";

import { createContext } from "react";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Routes, Outlet, Navigate, HashRouter } from "react-router-dom";

export const Auth = createContext();

const App = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user") ?? null)
  );
  const [userName, setUserName] = useState(
    JSON.parse(localStorage.getItem("username"))
  );
  const [token, setToken] = useState(localStorage.getItem("token"));
  const AuthToken = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  axios.defaults.baseURL = `${process.env.REACT_APP_PUBLIC_URL}/admin`;

  axios.defaults.headers.Authorization = AuthToken;

  const Home = () => {
    return (
      <>
        <Outlet />
      </>
    );
  };

  return (
    <>
      <ToastContainer />
      <Auth.Provider
        value={{ token, setToken, user, setUser, userName, setUserName }}
      >
        <HashRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={user ? <Home /> : <Navigate to="/login" />}
            >
              <Route index element={<Dashboard />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/users" element={<User />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/schools" element={<Schools />} />
              <Route exact path="/moderator" element={<Moderator />} />
              <Route exact path="/request-schools" element={<RequestTabs />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/chat/:id" element={<Chat />} />
              <Route exact path="/nominees/:id" element={<Nominees />} />
              <Route exact path="/members/:id" element={<Members />} />
              <Route exact path="/userdetails/:id" element={<UserDetails />} />

              <Route
                exact
                path="/Superlatives/:id"
                element={<Superlatives />}
              />
              <Route
                exact
                path="/common-superlatives"
                element={<CommonSuperlatives />}
              />

              <Route exact path="/Groups/:id" element={<Groups />} />
              <Route exact path="/common-groups" element={<CommonGroups />} />

              <Route exact path="/add_school" element={<AddSchool />} />
              <Route exact path="/edit_school/:id" element={<EditSchool />} />
            </Route>
            <Route exact path="/terms" element={<Terms />} />
            <Route
              exact
              path="/delete-user-account"
              element={<DeleteUserAccount />}
            />
            <Route
              exact
              path="/license-agreement"
              element={<UserLicenseAgreement />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/support" element={<Support />} />
            <Route exact path="/reported-posts" element={<ReportedPosts />} />
            <Route exact path="/reported-users" element={<ReportedUsers />} />
            <Route
              exact
              path="/login"
              element={user ? <Navigate to="/" /> : <Login />}
            />
            <Route path="*" element={<Notfound />} />
          </Routes>
        </HashRouter>
      </Auth.Provider>
    </>
  );
};

export default App;
